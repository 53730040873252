// Circular Timer Component
import React, { useState, useEffect } from 'react';


interface CircularTimerProps {
    duration: number;
    progress: number;
    size?: number;
    visible: boolean;

    onComplete: Function;
   
}
export const CircularTimer: React.FC<CircularTimerProps> = ({ progress ,duration,visible, onComplete }) => {

    return (
        <div style = {{position:'absolute', width:"80%", left:"10%" }} >{ visible&&
            <svg
                //width="90%"
                
                //height="90%"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="none"
                    stroke="white"
                    opacity={0.7}
                    strokeWidth="6"
                    strokeDasharray="283"
                    strokeDashoffset={283 * (1 - progress)}
                />
                <text x="50" y="50" textAnchor="middle" dominantBaseline="middle">
                    {duration - Math.floor(progress * duration)}
                </text>
            </svg>
            }
        </div>
    );
}