// GDPR component for the app
// This component is used to display the GDPR information to the user
// It is displayed when the user clicks on the GDPR button in the footer
// It is also displayed when the user first opens the app
// It is displayed as an alert

import { IonAlert } from "@ionic/react";
import { useEffect, useState } from "react";
import { UserStoragePrefs } from "../common/types";
import { Storage } from "@capacitor/storage";

// It is displayed as a modal
const Gdpr = (props: { isOpen: boolean }) => {
    const [fetched, setFetched] = useState(false);
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [consent, setConsent] = useState<UserStoragePrefs>({});
    const [saved, setSaved] = useState(false);
    useEffect(() => {
        async function getConsent() {
            const stored = await Storage.get({ key: 'consent' });
            if (stored.value) {
                setConsent(JSON.parse(stored.value));
            } else {
                setConsent({ model_inputs: true, orig_images: true, results: true, truth_data:false });
            }
            const saved = await Storage.get({ key: 'saved' });
            if (saved.value) {
                setSaved(saved.value === 'true');
            }
            setFetched(true);
        }
        getConsent();
    }, []);
    useEffect(() => {

        if (fetched) {
            if (!saved) {
                setIsOpen(true);
            }
            setIsOpen(props.isOpen || !saved);
        }
    }, [fetched, props.isOpen]);
    return <IonAlert
        isOpen={isOpen}
        onDidDismiss={(event) => {
            setIsOpen(false);
            const toStore = Object.fromEntries(
                event.detail.data.values.map((e: string) => [e, true])
            )
            console.log('toStore', toStore);
            setConsent(toStore);
            setSaved(true);
            Storage.set({ key: 'saved', value: 'true' });

            Storage.set({ key: 'consent', value: JSON.stringify(toStore) });
        }}
        cssClass='gdpr-alert'
        header={'GDPR'}
        subHeader={'General Data Protection Regulation'}
        inputs={[{
            type: 'checkbox', label: 'Store anonomys metadata', value: 'model_inputs', checked: consent.model_inputs, handler: (e) => {
                setConsent({ ...consent, ...{ model_inputs: e.checked } })
            }
        }, {
            type: 'checkbox', label: 'Store original images', value: 'orig_images', checked: consent.orig_images, handler: (e) => {
                setConsent({ ...consent, ...{ orig_images: e.checked } })
            }
        }, {
            type: 'checkbox', label: 'Store results', value: 'results', checked: consent.results, handler: (e) => {
                setConsent({ ...consent, ...{ results: e.checked } })
            }
        }]}
        message={'This app does not store any data by default. But to help us improve the app, we would like to store anonymous metadata generated from the images and user inputs, your results from the app (measurements and avatars) as well as the the original images. You can consent to all or none of these options.'}

        buttons={['Save settings']}
    ></IonAlert>

}
export default Gdpr;
