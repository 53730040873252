
const transforms = [
	"none",
	"scaleX(-1)",
	"rotate(180deg)",
	"scaleY(-1)",
	"scaleX(-1) rotate(-90deg)",
	"rotate(90deg)",
	"scaleX(-1) rotate(90deg)",
	"rotate(-90deg)",
];

export function orientationTransform(orient : number | undefined) {
	return transforms[(orient || 1) - 1];
}

