import {
  IonBackButton,
  IonButtons,
  IonContent, 
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackOutline,


} from "ionicons/icons";

import { InstructionSlides} from "../components/InstructionSlides";

import "../theme/Instructions.css";

import "../theme/Utilities.css";

const InstructionsOnSwipe: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/">
              <IonIcon slot="icon-only" icon={arrowBackOutline} />
            </IonBackButton>
          </IonButtons>
  
        </IonToolbar>
        <IonToolbar>
          <IonTitle className="titleToolbar">Swipe for Instructions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <InstructionSlides />
      </IonContent>
    </IonPage>
  );
};

export default InstructionsOnSwipe;
